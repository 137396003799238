#print{
    display: none;
}

@media print {
    .App{
        display: none;
    }
    #modal{
        display: none;
        visibility: hidden;
    }
    #print{
        display: block;
    }
}